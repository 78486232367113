<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }}
      </h1>
      <div class="panel" style="padding: 1em 0">
        <b-field label="ຊອກຫາ" horizontal>
          <b-input
            v-model="keyword"
            placeholder="ຊອກຫາ"
            @keyup.native.enter="search()"
          />
          <p class="control" v-if="!isAndroid">
            <b-button
              icon-left="magnify"
              class="button is-primary"
              @click="search()"
            ></b-button>
          </p>
          <p class="control" v-if="isAndroid">
            <b-button
              icon-left="barcode"
              class="button is-primary"
              @click="scanBarcode"
            ></b-button>
          </p>
        </b-field>
      </div>
      <card-component class="has-table has-mobile-sort-spaced">
        <b-table
          :loading="isLoading"
          :paginated="perPage < models.length"
          :per-page="perPage"
          :striped="true"
          :hoverable="true"
          default-sort="name"
          :data="models"
          :current-page.sync="currentPage"
          :pagination-rounded="true"
        >
          <b-table-column label="#" v-slot="props">
            {{ props.index+(currentPage>1?(perPage*currentPage-perPage):0)+1 }}
          </b-table-column>
          <b-table-column
            label="ວັນທີ່ສົ່ງ"
            field="code"
            sortable
            v-slot="props"
          >
            {{ props.row.created_date | datetime }}
          </b-table-column>
          <b-table-column label="ລະຫັດ" field="code" sortable v-slot="props">
            {{ props.row.code }}
            <span class="tag is-primary" v-if="props.row.is_cod">
               COD
            </span>
          </b-table-column>
          <b-table-column
            label="ສາຂາຕົ້ນທາງ"
            field="receivername"
            sortable
            v-slot="props"
          >
            {{ props.row.branch_branchToshipment_from_branch_id.name }}
          </b-table-column>
          <b-table-column
            label="ຜູ້ສົ່ງ"
            field="sendername"
            sortable
            v-slot="props"
          >
            {{ props.row.sendername }}
          </b-table-column>
          <b-table-column
            label="ຜູ້ຮັບ"
            field="receivername"
            sortable
            v-slot="props"
          >
            {{ props.row.receivername }}
          </b-table-column>
          <b-table-column
            label="ຄ່າຝາກ"
            field="amount"
            sortable
            v-slot="props"
            cell-class="has-text-right"
          >
            <span v-if="props.row.amount>0">
            {{ props.row.amount | formatnumber }} ກີບ
            </span>
            <span  v-if="props.row.amount_thb > 0">
            {{ props.row.amount_thb | formatnumber }} ບາດ
              </span>
          </b-table-column>
          <b-table-column
            label="ການຊໍາລະຄ່າຝາກ"
            field="is_receiver_pay"
            sortable
            v-slot="props"
            cell-class="has-text-centered"
          >
            <span v-if="!props.row.is_receiver_pay" class="tag is-success">
              ຈ່າຍແລ້ວ
            </span>
            <span
              v-if="props.row.is_receiver_pay && !props.row.is_fee_in_cod"
              class="tag is-warning"
            >
              ເກັບຄ່າສົ່ງປາຍທາງ
            </span>
            <span
              v-if="props.row.is_receiver_pay && props.row.is_fee_in_cod"
              class="tag is-danger"
            >
              ເກັບຄ່າສົ່ງປາຍທາງ (ຫັກຈາກ COD)
            </span>
          </b-table-column>
          <b-table-column
            custom-key="actions"
            cell-class="is-actions-cell"
            v-slot="props"
          >
            <div class="buttons is-right">
              <b-button
                class="is-small is-info"
                @click="view(props.row)"
                icon-left="eye"
              >
              </b-button>
            </div>
          </b-table-column>
          <empty-table v-if="models.length == 0" :isLoading="isLoading" />
        </b-table>
      </card-component>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import CardComponent from "@/components/CardComponent";
import EmptyTable from "../components/EmptyTable.vue";

export default {
  name: "ToDeliverParcels",
  components: { EmptyTable, CardComponent },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      title: "ເອົາເຄື່ອງໃຫ້ລູກຄ້າ",
      model: null,
      models: [],
      isShowModal: false,
      isLoading: false,
      keyword: null,
    };
  },
  mounted() {
    this.search();
  },
  watch: {
    models: function () {
      this.isLoading = false;
    },
  },
  methods: {
    view(item) {
      this.$router.push({ path: "/shipment/" + item.code });
    },
    search() {
      this.isLoading = true;
      axios.get("parcels/todelivertoreceiver").then((r) => {
        this.models = r;
        if (this.keyword) {
          this.keyword = this.keyword.trim().split(/\//).pop();
          this.models = this._.filter(
            r,
            (e) => e.code.indexOf(this.keyword) >= 0
          );
          if (this.models && this.models.length === 1) {
            this.$router.push({ path: "/shipment/" + this.models[0].code });
          }
        }
      });
    },

    find(barcode) {
      console.log("finding barcode: ", barcode);
      axios
        .get(`parcels/getfordelivery/${barcode}`)
        .then((parcel) => {
          this.$router.push({
            path: "/shipment/" + parcel.code,
            params: { propShipment: this.shipment },
          });
        })
        .catch((e) => {
          console.log(e);
          this.$buefy.snackbar.open({
            message: "Barcode not found",
            queue: false,
          });
        });
    },

    scanBarcode() {
      this.$buefy.snackbar.open({
        message: "Scan barcode",
        queue: false,
      });

      try {
        return window.AndroidApp.scanBarcode("dlBarcodeScanned");
      } catch (e) {
        console.log(e);
      }
    },

    toDeliverParcelsBarcodeScanned(barcode) {
      this.$buefy.snackbar.open({
        message: `Scan barcode : ${barcode}`,
        queue: false,
      });
      if (barcode !== undefined && barcode.length > 0) {
        this.find(barcode);
      }
    },
  },
  beforeMount() {
    //Create interface between Webview and Native application
    window["MyWebView"] = {
      components: this,
      dlBarcodeScanned: (barcode) =>
        this.toDeliverParcelsBarcodeScanned(barcode),
    };
  },
};
</script>